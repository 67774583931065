import MaterialTable, {
  Action,
  Query,
  QueryResult,
  Components,
  MTableToolbar,
} from 'material-table';
import React, { useEffect } from 'react';
import './Table.scss';
import { Box, Typography, Container, makeStyles, createStyles, Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface IProps {
  search: boolean;
  title: string;
  tableColumns: any[];
  actions: (Action<any> | ((rowData: any) => Action<any>))[];
  pageSize: number;
  refreshCount: number;
  errorMessage: string;
  components: Components;
  loadData(query: Query<object>): Promise<QueryResult<object>>;
  onRowClick(
    event?: React.MouseEvent,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number) => void,
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTitle: {
      paddingTop: theme.spacing(2),
    },
  }),
);

export const TABLE_PAGE_SIZE = 10;

export const Table = (props: IProps) => {
  const classes = useStyles();
  const tableRef = React.createRef<any>();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [props.refreshCount]);

  return (
    <Box>
      <MaterialTable
        tableRef={tableRef}
        components={{
          ...props.components,
          Toolbar: (props) => (
            <Box>
              <Container maxWidth="xl" className={classes.tableTitle}>
                <Typography variant="h4">{props.title}</Typography>
              </Container>
              <MTableToolbar {...props} />
            </Box>
          ),
        }}
        columns={props.tableColumns}
        data={(query) => props.loadData(query)}
        title={props.title}
        options={{
          search: props.search,
          debounceInterval: 500,
          emptyRowsWhenPaging: false,
          pageSize: props.pageSize,
          toolbarButtonAlignment: 'left',
          showTitle: false,
        }}
        localization={{
          header: {
            actions: 'Akcie',
          },
          body: {
            emptyDataSourceMessage: '',
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} z {count}',
            labelRowsSelect: 'záznamov',
          },
        }}
        actions={props.actions}
        onRowClick={props.onRowClick}
      />
      {props.errorMessage != '' && <Alert severity="error">{props.errorMessage}</Alert>}
    </Box>
  );
};
