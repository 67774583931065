import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { IAuthenticationResult } from '../../interfaces/IAuthenticationResult';
import { Api } from '../../services/Api';
import { LoginForm } from '../LoginForm';
import { useCookies } from 'react-cookie';
import './Login.scss';
import { getExpDate } from '../../services/Auth';
import { AppCookies } from '../../services/AppCookies';

interface IProps {
  handleLogin(authenticationResult: IAuthenticationResult): void;
  isAuthenticated: boolean;
}

export const Login = (props: IProps) => {
  const [isFetchingData, setFetchingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cookies, setCookie] = useCookies(['']);

  const handleLogin = (email: string, password: string): void => {
    setFetchingData(true);
    Api.authenticateUser(email, password, (authResult: IAuthenticationResult): void => {
      setFetchingData(authResult.isFetchingData);
      setErrorMessage(authResult.errorMessage);
      if (authResult.isAuthenticated) {
        setLoginCookies(authResult.loginInfo.token, authResult.loginInfo.email);
        props.handleLogin(authResult);
      }
    });
  };

  const setLoginCookies = (token: string, email: string): void => {
    const expDate = getExpDate(token);
    setCookie(AppCookies.TOKEN, token, { expires: expDate });
    setCookie(AppCookies.EMAIL, email, { expires: expDate });
  };

  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return (
      <LoginForm
        isFetchingData={isFetchingData}
        errorMessage={errorMessage}
        handleLogin={handleLogin}
      />
    );
  }
};
