import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

// TODO: fix any
export const PrivateRoute = ({ component, isAuthenticated, loginInfo, logout, ...rest }: any) => {
  if (!component) {
    throw Error('component is undefined');
  }

  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps): React.ReactNode => {
    if (isAuthenticated) {
      return (
        <Component
          {...props}
          isAuthenticated={isAuthenticated}
          loginInfo={loginInfo}
          logout={logout}
        />
      );
    }
    return <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={render} />;
};
