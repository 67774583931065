import React, { useState, useEffect } from 'react';
import { IDataIndicatorValue } from '../../interfaces/IDataIndicatorValue';
import { ILoginInfo } from '../../interfaces/ILoginInfo';
import { IOption } from '../../interfaces/IOption';
import { IPatient } from '../../interfaces/IPatient';
import { IPatientData } from '../../interfaces/IPatientData';
import { Api } from '../../services/Api';
import { PatientDialogForm } from '../PatientDialogForm';
import { EmptyObjectCreator, Status, getPatientInfo, AppResponse } from '../utils/Utils';
import './PatientDialog.scss';

interface IProps {
  loginInfo: ILoginInfo;
  patient: IPatient;
  dialogOpen: boolean;
  logout(): void;
  closeDialog(refresh: boolean): void;
}

export const AVPU = 'AVPU';
export const OXYGEN_INHALATION = 'INHALACIA';

export const PatientDialog = (props: IProps) => {
  const [isSavingData, setSavingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataIndicatorValues, setDataIndicatorValues] = useState([
    EmptyObjectCreator.dataIndicatorValue,
  ]);
  const [avpuOptions, setAvpuOptions] = useState([EmptyObjectCreator.option]);
  const [oxygenInhalationOptions, setOxygenInhalationOptions] = useState([
    EmptyObjectCreator.option,
  ]);

  useEffect(() => {
    Api.getDataIndicatorValues(props.loginInfo.token, (result: AppResponse) => {
      if (result.statusCode === 401) {
        props.logout();
      } else if (result.status !== Status.Error) {
        setAvpuOptions(getOptions(AVPU, dataIndicatorValues));
        setOxygenInhalationOptions(getOptions(OXYGEN_INHALATION, dataIndicatorValues));
        setDataIndicatorValues(dataIndicatorValues);
      }
    });
  }, []);

  const getOptions = (code: string, dataIndicatorValues: IDataIndicatorValue[]): IOption[] => {
    return dataIndicatorValues
      .filter((data) => data.code === code)
      .sort((d1, d2) => d1.order - d2.order)
      .map((data) => ({ value: data.graphicValue, label: data.value }));
  };

  const handleSubmit = (patiendData: IPatientData): void => {
    setSavingData(true);
    Api.createPatientData(patiendData, props.loginInfo.token, (result: Status): void => {
      if (result == Status.Ok) {
        setSavingData(false);
        setErrorMessage('');
        props.closeDialog(true);
      } else {
        setSavingData(false);
        setErrorMessage('Vyskytla sa chyba pri ukladaní dát.');
      }
    });
  };

  const closeDialog = (): void => {
    if (!isSavingData) {
      setSavingData(false);
      setErrorMessage('');
      props.closeDialog(false);
    }
  };

  return (
    <PatientDialogForm
      patientId={props.patient.id}
      patientInfo={getPatientInfo(props.patient)}
      dataIndicatorValues={dataIndicatorValues}
      avpuOptions={avpuOptions}
      oxygenInhalationOptions={oxygenInhalationOptions}
      errorMessage={errorMessage}
      isSavingData={isSavingData}
      dialogOpen={props.dialogOpen}
      handleSubmit={handleSubmit}
      closeDialog={closeDialog}
    />
  );
};
