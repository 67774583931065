import {
  Box,
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { IAuthenticationResult } from '../interfaces/IAuthenticationResult';
import { theme } from '../theme';
import './App.scss';
import { Footer } from './Footer';
import { Header } from './Header';
import { Login } from './Login';
import { PatientData } from './PatientData';
import { Patients } from './Patients';
import { PrivateRoute } from './PrivateRoute';
import { EmptyObjectCreator, getAuthResult, getLoginInfo } from './utils/Utils';
import { useCookies } from 'react-cookie';
import { getExpDate } from '../services/Auth';
import { AppCookies } from '../services/AppCookies';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dFlex: {
      display: 'flex',
      flexDirection: 'column',
    },
    height100vh: {
      height: '100vh',
    },
    flex1: {
      flex: '1',
    },
    flex1Auto: {
      flex: '1 auto',
    },
  }),
);

export const App = () => {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies(['']);

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginInfo, setLoginInfo] = useState(EmptyObjectCreator.loginInfo);

  useEffect(() => {
    const token = cookies.token;
    const email = cookies.email;
    if (token && email) {
      const expDate = getExpDate(token);
      if (expDate.valueOf() > new Date().valueOf()) {
        setStateAfterAuthenticate(getAuthResult(true, getLoginInfo(email, token), ''));
      }
    }
  }, []);

  const setStateAfterAuthenticate = (authenticationResult: IAuthenticationResult): void => {
    setLoginInfo(authenticationResult.loginInfo);
    setAuthenticated(authenticationResult.isAuthenticated);
  };

  const logout = (): void => {
    removeCookie(AppCookies.EMAIL);
    removeCookie(AppCookies.TOKEN);
    setAuthenticated(false);
    setLoginInfo(EmptyObjectCreator.loginInfo);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.height100vh}>
        <Router>
          <Box className={`${classes.dFlex} ${classes.height100vh}`}>
            <Header isAuthenticated={isAuthenticated} handleLogout={logout} />
            <Box className={`${classes.dFlex} ${classes.flex1}`}>
              <Box className={`${classes.dFlex} ${classes.flex1Auto}`}>
                <Switch>
                  <Route
                    path="/login"
                    exact
                    render={() => (
                      <Login
                        handleLogin={setStateAfterAuthenticate}
                        isAuthenticated={isAuthenticated}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/patient/:patientId"
                    exact
                    isAuthenticated={isAuthenticated}
                    loginInfo={loginInfo}
                    logout={logout}
                    component={PatientData}
                  />
                  <PrivateRoute
                    path="/"
                    exact
                    isAuthenticated={isAuthenticated}
                    loginInfo={loginInfo}
                    logout={logout}
                    component={Patients}
                  />
                  <Redirect to="/" />
                </Switch>
              </Box>
              <Footer />
            </Box>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;
