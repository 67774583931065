import jwt from 'jsonwebtoken';

export const getToken = (token: string): any => {
  return jwt.decode(getTokenValue(token)) as any;
};

export const getTokenValue = (token: string): string => {
  return token.replace('Bearer ', '');
};

export const getExpDate = (token: string): Date => {
  const dateExp = new Date(0);
  dateExp.setUTCSeconds(getToken(token).exp);
  return dateExp;
};
