import axios, { AxiosResponse } from 'axios';
import {
  AppResponse,
  EmptyObjectCreator,
  getAuthResult,
  getLoginInfo,
  notEmpty,
  Status,
} from '../components/utils/Utils';
import { IAuthenticationResult } from '../interfaces/IAuthenticationResult';
import { IPatientData } from '../interfaces/IPatientData';

/**
 * Contains all API endpoints.
 */
export class ApiEndpoints {
  private static readonly API_URL = process.env.REACT_APP_API_URL;
  static readonly LoginEndpoint = ApiEndpoints.API_URL + '/api/login';
  static readonly PatientsEndpoint = ApiEndpoints.API_URL + '/api/patients';
  static readonly PatientDataEndpoint = ApiEndpoints.API_URL + '/api/patientdata';
  static readonly DataIndicatorValuesEndpoint = ApiEndpoints.API_URL + '/api/dataindicatorvalues';
}

/**
 * Class contains all api methods to get data.
 */
export class Api {
  static readonly PAGE_SIZE = 'pageSize';
  static readonly PAGE = 'page';
  static readonly ORDER_BY_FIELD = 'orderByField';
  static readonly ORDER_BY_DIRECTION = 'orderByDirection';
  static readonly SEARCH = 'search';
  private static loginOrEmailInvalid = 'Email alebo heslo nie sú správne.';

  private static getAppResponse(status: Status, data: any, statusCode: number): AppResponse {
    const page = data !== null && data.page !== undefined ? data.page : 0;
    const total = data !== null && data.total !== undefined ? data.total : 0;
    return {
      status: status,
      statusCode: statusCode,
      data: data !== null ? data.data : [],
      page: page,
      total: total,
    };
  }

  private static get(url: string, token: string, callback: (result: AppResponse) => void) {
    axios
      .get(url, this.getAuthorizationOptions(token))
      .then((response) => {
        callback(Api.getAppResponse(Status.Ok, response.data, response.status));
      })
      .catch((error) => {
        callback(Api.getAppResponse(Status.Error, null, error.response.status));
      });
  }

  private static getAuthorizationOptions(token: string): any {
    return {
      headers: {
        Authorization: token,
      },
    };
  }

  private static getQueryParam(query: any): string {
    let url = `${Api.PAGE_SIZE}=${query.pageSize}&${Api.PAGE}=${query.page}`;
    if (notEmpty(query.search)) {
      url += `&${Api.SEARCH}=${query.search}`;
    }
    if (query.orderBy !== undefined && notEmpty(query.orderDirection)) {
      url += `&${Api.ORDER_BY_FIELD}=${query.orderBy.field}&${Api.ORDER_BY_DIRECTION}=${query.orderDirection}`;
    }
    return url;
  }

  /**
   * Authenticates user and sets {@link IAuthenticationResult}.
   * @param email - user email
   * @param password - user variable symbol
   * @param callback - callback function that is call when succeded
   */
  static authenticateUser = (
    email: string,
    password: string,
    callback: (authResult: IAuthenticationResult) => void,
  ): void => {
    axios
      .post(ApiEndpoints.LoginEndpoint, {
        email: email,
        password: password,
      })
      .then((response) => {
        callback(Api.getLoginSuccessResult(email, response));
      })
      .catch((error) => {
        const msg =
          error.response && error.response.status === 401
            ? Api.loginOrEmailInvalid
            : 'Vyskytla sa chyba.';
        callback(getAuthResult(false, EmptyObjectCreator.loginInfo, msg));
      });
  };

  private static getLoginSuccessResult(
    email: string,
    response: AxiosResponse<any>,
  ): IAuthenticationResult {
    if (response.status === 200) {
      return getAuthResult(true, getLoginInfo(email, response.headers['authorization']), '');
    } else {
      return getAuthResult(false, EmptyObjectCreator.loginInfo, Api.loginOrEmailInvalid);
    }
  }

  /**
   * Gets all patients.
   * @param query - query object from table
   * @param token - authorization token
   * @param callback - callback method called when succeded
   */
  static getPatients(query: any, token: string, callback: (result: AppResponse) => void) {
    const options = {
      headers: {
        Authorization: token,
      },
    };
    Api.get(`${ApiEndpoints.PatientsEndpoint}?${this.getQueryParam(query)}`, token, callback);
  }

  /**
   * Gets all patient's data.
   * @param patienId - patient id
   * @param query - query object from table
   * @param token - authorization token
   * @param callback - callback method called when succeded
   */
  static getPatientDataById(
    patientId: number,
    query: any,
    token: string,
    callback: (result: AppResponse) => void,
  ) {
    Api.get(
      `${ApiEndpoints.PatientDataEndpoint}/${patientId}?${this.getQueryParam(query)}`,
      token,
      callback,
    );
  }

  /**
   * Gets all data indicator values.
   * @param token - authorization token
   * @param callback - callback method called when succeded
   */
  static getDataIndicatorValues(token: string, callback: (result: AppResponse) => void) {
    Api.get(ApiEndpoints.DataIndicatorValuesEndpoint, token, callback);
  }

  /**
   * Create patient data.
   * @param patientData - IPatientData
   * @param token - authorization token
   * @param callback - callback function that is call when succeded
   */
  static createPatientData = (
    patientData: IPatientData,
    token: string,
    callback: (result: Status) => void,
  ): void => {
    axios
      .post(ApiEndpoints.PatientDataEndpoint, patientData, Api.getAuthorizationOptions(token))
      .then((response) => {
        if (response.status === 200) {
          callback(Status.Ok);
        } else {
          callback(Status.Error);
        }
      })
      .catch((error) => {
        callback(Status.Error);
      });
  };
}
