import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import './LoginForm.scss';
import { Container, Typography, Theme, Divider, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface IProps {
  isFetchingData: boolean;
  errorMessage: string;
  handleLogin(emal: string, password: string): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginForm: {
      marginTop: theme.spacing(16),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(8),
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(6),
    },
    w100_mt1: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

export const LoginForm = (props: IProps) => {
  const classes = useStyles();
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    props.handleLogin(inputEmail, inputPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box boxShadow={3} className={classes.loginForm}>
        <Typography component="h1" variant="h5">
          Prihlásenie
        </Typography>
        <Divider className={classes.w100_mt1} />
        <form className={classes.w100_mt1} onSubmit={handleSubmit}>
          <TextField
            id="email"
            name="email"
            label="Používateľ [email]"
            value={inputEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputEmail(e.currentTarget.value)
            }
            margin="normal"
            fullWidth
            required
            autoFocus
          />
          <TextField
            id="password"
            name="password"
            type="password"
            label="Heslo"
            value={inputPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputPassword(e.currentTarget.value)
            }
            fullWidth
            margin="normal"
            required
          />
          {props.errorMessage != '' && (
            <Alert severity="error" data-testid="errorAlert">
              {props.errorMessage}
            </Alert>
          )}
          {!props.isFetchingData && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-testid="submitLogin"
            >
              Prihlásiť sa
            </Button>
          )}
          {props.isFetchingData && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-testid="submitLoading"
              disabled
            >
              <Box
                component="span"
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              />
              Načítanie...
            </Button>
          )}
        </form>
      </Box>
    </Container>
  );
};
