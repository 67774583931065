import { IDataIndicatorValue } from '../../interfaces/IDataIndicatorValue';
import { ILoginInfo } from '../../interfaces/ILoginInfo';
import { IOption } from '../../interfaces/IOption';
import { IPatient } from '../../interfaces/IPatient';
import { IPatientData } from '../../interfaces/IPatientData';
import { IAuthenticationResult } from '../../interfaces/IAuthenticationResult';

/**
 * Enum to help recognize chosen submenu in portal.
 */
export enum SubMenu {
  Info,
  ClassBook,
  Attendance,
  Payments,
}

/**
 * Enum to help recognize step in registration.
 */
export enum RegistrationStep {
  Info,
  LectureSelection,
  Summary,
  Completion,
}

export enum Status {
  Ok,
  Error,
}

export interface AppResponse {
  status: Status;
  statusCode: number;
  data: any;
  page: number;
  total: number;
}

/**
 * Creates empty object for certain entities.
 */
export class EmptyObjectCreator {
  static loginInfo: ILoginInfo = {
    email: '',
    token: '',
  };

  static patient: IPatient = {
    id: -1,
    name: '',
    surname: '',
    age: 0,
    lastScore: -1,
    lastNurseAction: '',
    lastDoctorAction: '',
    scoreStartTime: new Date(),
    lastScoreTime: new Date(),
  };

  static patientData: IPatientData = {
    id: -1,
    patientId: -1,
    name: '',
    surname: '',
    age: 0,
    dateAndTime: new Date(),
    score: -1,
    nurseAction: '',
    doctorAction: '',
    respiratoryFrequency: '',
    heartRate: '',
    bloodPressure: '',
    bodyTemperature: '',
    saturation: '',
    avpu: '',
    avpuId: -1,
    oxygenInhalation: '',
    oxygenInhalationId: -1,
    lastId: -1,
  };

  static dataIndicatorValue: IDataIndicatorValue = {
    id: -1,
    code: '',
    value: '',
    order: 0,
    graphicValue: 0,
  };

  static option: IOption = {
    value: -1,
    label: '',
  };
}

export const getPatientInfo = (patient: IPatient): string => {
  return patient.id === -1 ? '' : patient.name + ' ' + patient.surname + ', vek: ' + patient.age;
};

export const notEmpty = (value: string): boolean => {
  return value !== undefined && value !== null && value !== '';
};

export const getDecimalSeparator = (): string => {
  const num = 1.1;
  const part = new Intl.NumberFormat().formatToParts(num).find((part) => part.type === 'decimal');
  const decimalSeparator = part !== undefined ? part.value : num.toLocaleString().substring(1, 2);
  return decimalSeparator;
};

export const getLoginInfo = (email: string, token: string): ILoginInfo => {
  return {
    email: email,
    token: token,
  };
};

export const getAuthResult = (
  isAuthenticated: boolean,
  loginInfo: ILoginInfo,
  errMsg: string,
  isFetchingData = false,
): IAuthenticationResult => {
  return {
    isAuthenticated: isAuthenticated,
    loginInfo: loginInfo,
    isFetchingData: isFetchingData,
    errorMessage: errMsg,
  };
};

export const formatNummber = (num: number): string => {
  return num.toLocaleString();
};

export const getNurseStyle = (): {} => {
  return { backgroundColor: '#ffb40a', minWidth: '400px' };
};

export const getDoctorStyle = (): {} => {
  return { backgroundColor: '#fde856', minWidth: '400px' };
};

export class Utils {
  static dayShortToLong = (dayShort: string) => {
    switch (dayShort) {
      case 'pon':
        return 'Pondelok';
      case 'uto':
        return 'Utorok';
      case 'str':
        return 'Streda';
      case 'stv':
        return 'Štvrtok';
      case 'pia':
        return 'Piatok';
      case 'sob':
        return 'Sobota';
      case 'ned':
        return 'Nedeľa';
      default:
        return '';
    }
  };

  // s is format y-m-d
  // Returns a date object for 00:00:00 local time
  // on the specified date
  static parseDate = (stringDate: string) => {
    let b: string[] = stringDate.split(/\D/);
    return new Date(Number(b[0]), Number(b[1]), Number(b[2]));
  };

  static isValidDate = (d: any) => {
    return !isNaN(d.valueOf());
  };

  static getISODateFormat = (date: Date) => {
    return Utils.isValidDate(date) ? date.toISOString().substring(0, 10) : '2000-01-01';
  };

  static isNullOrEmpty = (string: string) =>
    string === undefined || string === null || string === '';

  static isEmpty = (obj: any) => {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }

    return true;
  };
}
