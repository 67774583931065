import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

interface IProps {
  isAuthenticated: boolean;
  handleLogout(event: React.MouseEvent<HTMLButtonElement>): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      paddingBottom: '0',
      paddingTop: '0',
      backgroundColor: theme.palette.common.black,
    },
    navbarLogo: {
      display: 'inline-block',
      padding: theme.spacing(2, 3),
      lineHeight: '0.7',
    },
    signOutBtn: {
      margin: theme.spacing(2, 3),
      float: 'right',
    },
  }),
);

export const Header = (props: IProps) => {
  const classes = useStyles();

  return (
    <nav className={classes.navbar}>
      <Link className={classes.navbarLogo} to="/">
        <img src="/img/metaapp-logo.svg" alt="MetaApp" />
      </Link>
      {props.isAuthenticated && (
        <Button
          variant="contained"
          color="primary"
          className={classes.signOutBtn}
          onClick={props.handleLogout}
        >
          Odhlásiť
        </Button>
      )}
    </nav>
  );
};
