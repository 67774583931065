import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: '#4633af',
      },
      secondary: {
        main: '#FF6A00',
      },
      background: {
        default: '#ffffff',
      },
      common: {
        white: '#fefefa',
        black: '#333',
      },
    },
    typography: {
      fontFamily: 'Roboto',
    },
  }),
);
