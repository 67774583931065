import { Box } from '@material-ui/core';
import { Query, QueryResult } from 'material-table';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ILoginInfo } from '../../interfaces/ILoginInfo';
import { IPatient } from '../../interfaces/IPatient';
import { Api } from '../../services/Api';
import { PatientDialog } from '../PatientDialog';
import { Table, TABLE_PAGE_SIZE } from '../Table';
import {
  EmptyObjectCreator,
  getDoctorStyle,
  getNurseStyle,
  Status,
  AppResponse,
} from '../utils/Utils';
import './Patients.scss';

interface IProps extends RouteComponentProps<any> {
  loginInfo: ILoginInfo;
  logout(): void;
}

export const Patients = (props: IProps) => {
  const [patients, setPatients] = useState([] as IPatient[]);
  const [patient, setPatient] = useState(EmptyObjectCreator.patient);
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);
  const [refreshCount, refreshTableData] = useState(0);

  const tableColumns: any = [
    { title: 'Meno', field: 'name' },
    { title: 'Priezvisko', field: 'surname', defaultSort: 'asc' },
    { title: 'Vek', field: 'age', type: 'numeric' },
    { title: 'Posledné skóre', field: 'lastScore', type: 'numeric' },
    {
      title: 'Posledná akcia zdravotnej sestry',
      field: 'lastNurseAction',
      cellStyle: getNurseStyle(),
      headerStyle: getNurseStyle(),
    },
    {
      title: 'Posledná akcia lekára',
      field: 'lastDoctorAction',
      cellStyle: getDoctorStyle(),
      headerStyle: getDoctorStyle(),
    },
    { title: 'Začiatok skórovania', field: 'scoreStartTime', type: 'datetime' },
    { title: 'Posledné skórovanie', field: 'lastScoreTime', type: 'datetime' },
  ];

  const handleAddBtnAction = (event: any, patient: IPatient): void => {
    setPatient(patient);
    setDialogOpen(true);
  };

  const closeDialog = (refresh: boolean): void => {
    setDialogOpen(false);
    if (refresh) {
      refreshTableData(refreshCount + 1);
    }
  };

  const loadData = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      Api.getPatients(query, props.loginInfo.token, (response: AppResponse): any => {
        processLoadDataResponse(response, query, resolve);
      });
    });
  };

  const processLoadDataResponse = (
    response: AppResponse,
    query: Query<object>,
    resolve: (value?: QueryResult<object> | PromiseLike<QueryResult<object>> | undefined) => void,
  ): void => {
    if (response.statusCode === 401) {
      props.logout();
    } else {
      setPageSize(query.pageSize);
      setErrorMessage(
        response.status === Status.Error ? 'Niekde nastala chyba! Nepodarilo sa načítať dáta.' : '',
      );
      if (response.status === Status.Ok) {
        setPatients(response.data);
      }
      resolve({
        data: response.data,
        page: response.page,
        totalCount: response.total,
      });
    }
  };

  const actions = [
    {
      icon: 'add',
      tooltip: 'Pridaj záznam',
      onClick: (event: any, rowData: IPatient) => handleAddBtnAction(event, rowData as IPatient),
    },
  ];

  const onRowClick = (event: React.MouseEvent, rowData: any): void => {
    props.history.push('/patient/' + (rowData as IPatient).id);
  };

  return (
    <Box>
      <Table
        search={true}
        title={'Systém včasného varovania'}
        pageSize={pageSize}
        tableColumns={tableColumns}
        actions={actions}
        refreshCount={refreshCount}
        errorMessage={errorMessage}
        components={{}}
        loadData={loadData}
        onRowClick={onRowClick}
      />
      <PatientDialog
        loginInfo={props.loginInfo}
        patient={patient}
        dialogOpen={dialogOpen}
        logout={props.logout}
        closeDialog={closeDialog}
      />
    </Box>
  );
};
