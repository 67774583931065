import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { IDataIndicatorValue } from '../../interfaces/IDataIndicatorValue';
import { IOption } from '../../interfaces/IOption';
import { IPatientData } from '../../interfaces/IPatientData';
import { EmptyObjectCreator, formatNummber, getDecimalSeparator, notEmpty } from '../utils/Utils';
import './PatientDialogForm.scss';
import { AVPU, OXYGEN_INHALATION } from '../PatientDialog';
import Box from '@material-ui/core/Box';

interface IProps {
  patientId: number;
  patientInfo: string;
  dataIndicatorValues: IDataIndicatorValue[];
  avpuOptions: IOption[];
  oxygenInhalationOptions: IOption[];
  errorMessage: string;
  dialogOpen: boolean;
  isSavingData: boolean;
  handleSubmit(patiendData: IPatientData): void;
  closeDialog(): void;
}

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={getDecimalSeparator()}
      isNumericString
    />
  );
}

interface IState {
  respiratoryFrequency: string;
  heartRate: string;
  bloodPressure: string;
  bodyTemperature: string;
  avpu: string;
  avpuId: number;
  saturation: string;
  oxygenInhalation: string;
  oxygenInhalationId: number;
}

const getEmptyState = (): IState => {
  return {
    respiratoryFrequency: '',
    heartRate: '',
    bloodPressure: '',
    bodyTemperature: '',
    avpu: '',
    avpuId: -1,
    saturation: '',
    oxygenInhalation: '',
    oxygenInhalationId: -1,
  };
};

export const PatientDialogForm = (props: IProps) => {
  const [state, setState] = useState(getEmptyState());

  const getPatientData = (): IPatientData => {
    const patientData = EmptyObjectCreator.patientData;
    patientData.patientId = props.patientId;
    patientData.respiratoryFrequencyNum = +state.respiratoryFrequency;
    patientData.respiratoryFrequency = formatNummber(patientData.respiratoryFrequencyNum);
    patientData.heartRateNum = +state.heartRate;
    patientData.heartRate = formatNummber(patientData.heartRateNum);
    patientData.bloodPressureNum = +state.bloodPressure;
    patientData.bloodPressure = formatNummber(patientData.bloodPressureNum);
    patientData.bodyTemperatureNum = +state.bodyTemperature;
    patientData.bodyTemperature = formatNummber(patientData.bodyTemperatureNum);
    patientData.saturationNum = +state.saturation;
    patientData.saturation = formatNummber(patientData.saturationNum);
    patientData.avpu = state.avpu;
    patientData.avpuId = state.avpuId;
    patientData.oxygenInhalation = state.oxygenInhalation;
    patientData.oxygenInhalationId = state.oxygenInhalationId;
    return patientData;
  };

  const closeDialog = (): void => {
    if (!props.isSavingData) {
      setState(getEmptyState());
      props.closeDialog();
    }
  };

  const handleChange = (e: any): void => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: any): void => {
    const code = e.target.name === 'avpu' ? AVPU : OXYGEN_INHALATION;
    const value = props.dataIndicatorValues.find(
      (data) => data.code === code && data.graphicValue === e.target.value,
    );
    let valueId = -1;
    if (value !== undefined) {
      valueId = value.id;
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      [e.target.name + 'Id']: valueId,
    }));
  };

  const filedsNotEmpty = (values: string[]): boolean => {
    let fieldsNotEmpty = false;
    if (!props.isSavingData) {
      fieldsNotEmpty = true;
      for (let i = 0; i < values.length; i++) {
        if (!notEmpty(values[i])) {
          fieldsNotEmpty = false;
          break;
        }
      }
    }
    return fieldsNotEmpty;
  };

  const {
    respiratoryFrequency,
    heartRate,
    bloodPressure,
    bodyTemperature,
    avpu,
    saturation,
    oxygenInhalation,
  } = state;

  const submitEnabled = filedsNotEmpty([
    respiratoryFrequency,
    heartRate,
    bloodPressure,
    bodyTemperature,
    avpu,
    saturation,
    oxygenInhalation,
  ]);

  return (
    <Box>
      <Dialog open={props.dialogOpen} onClose={closeDialog}>
        <DialogTitle>Hodnoty EWS - {props.patientInfo}</DialogTitle>
        <DialogContent dividers={true}>
          <TextField
            id="respiratoryFrequency"
            name="respiratoryFrequency"
            label="Frekvencia dychov / min."
            value={respiratoryFrequency}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ inputComponent: NumberFormatCustom as any }}
            data-testid="respiratoryFrequency"
          />
          <TextField
            id="heartRate"
            name="heartRate"
            label="Frekvencia srdca / min."
            value={heartRate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ inputComponent: NumberFormatCustom as any }}
            data-testid="heartRate"
          />
          <TextField
            id="bloodPressure"
            name="bloodPressure"
            label="Tlak krvi sTK / mmHg"
            value={bloodPressure}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ inputComponent: NumberFormatCustom as any }}
            data-testid="bloodPressure"
          />
          <TextField
            id="bodyTemperature"
            name="bodyTemperature"
            label="Teplota tela v °C"
            value={bodyTemperature}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ inputComponent: NumberFormatCustom as any }}
            data-testid="bodyTemperature"
          />
          <TextField
            select
            id="avpu"
            name="avpu"
            label="Vedomie a Reakcie - AVPU"
            value={avpu}
            onChange={handleSelectChange}
            fullWidth
            data-testid="avpu"
          >
            {props.avpuOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="saturation"
            name="saturation"
            label="Saturácia kyslíkom (%)"
            value={saturation}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ inputComponent: NumberFormatCustom as any }}
            data-testid="saturation"
          />
          <TextField
            select
            id="oxygenInhalation"
            name="oxygenInhalation"
            label="Inhalácia kyslíka"
            value={oxygenInhalation}
            onChange={handleSelectChange}
            fullWidth
            data-testid="oxygenInhalation"
          >
            {props.oxygenInhalationOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {props.errorMessage != '' && (
            <Alert className="my-3" severity="error" data-testid="errorAlert">
              {props.errorMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog} disabled={props.isSavingData}>
            Zatvoriť
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => props.handleSubmit(getPatientData())}
            disabled={props.isSavingData || !submitEnabled}
            data-testid="submit"
          >
            Uložiť
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
