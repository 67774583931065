import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Query, QueryResult } from 'material-table';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ILoginInfo } from '../../interfaces/ILoginInfo';
import { IPatient } from '../../interfaces/IPatient';
import { IPatientData } from '../../interfaces/IPatientData';
import { Api } from '../../services/Api';
import { PatientDialog } from '../PatientDialog';
import { Table, TABLE_PAGE_SIZE } from '../Table';
import {
  EmptyObjectCreator,
  getDoctorStyle,
  getNurseStyle,
  getPatientInfo,
  Status,
  AppResponse,
} from '../utils/Utils';
import './PatientData.scss';

interface IProps extends RouteComponentProps<any> {
  loginInfo: ILoginInfo;
  logout(): void;
}

export const PatientData = (props: IProps) => {
  const [patientData, setPatientData] = useState([] as IPatientData[]);
  const [patient, setPatient] = useState(EmptyObjectCreator.patient);
  const [errorMessage, setErrorMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);
  const [refreshCount, refreshTableData] = useState(0);

  const tableColumns: any = [
    { title: 'Dátum a čas', field: 'dateAndTime', type: 'datetime', defaultSort: 'desc' },
    { title: 'Skóre', field: 'score', type: 'numeric' },
    {
      title: 'Akcia zdravotnej sestry',
      field: 'nurseAction',
      cellStyle: (data: any[], rowData: any) => getCellStyle(rowData, getNurseStyle()),
      headerStyle: getNurseStyle(),
    },
    {
      title: 'Akcia lekára',
      field: 'doctorAction',
      cellStyle: (data: any[], rowData: any) => getCellStyle(rowData, getDoctorStyle()),
      headerStyle: getDoctorStyle(),
    },
    { title: 'F dychov', field: 'respiratoryFrequency' },
    { title: 'HR', field: 'heartRate' },
    { title: 'sTK', field: 'bloodPressure' },
    { title: 'TT', field: 'bodyTemperature' },
    { title: 'AVPU', field: 'avpu' },
    { title: 'SpO2', field: 'saturation' },
    { title: 'FiO2', field: 'oxygenInhalation' },
  ];

  const getCellStyle = (rowData: any, style: {}): any => {
    return rowData.lastId === rowData.id ? style : null;
  };

  const loadData = (query: Query<object>): Promise<QueryResult<object>> => {
    return new Promise((resolve, reject) => {
      Api.getPatientDataById(
        props.match.params.patientId,
        query,
        props.loginInfo.token,
        (response: AppResponse): any => {
          processLoadDataResponse(response, query, resolve);
        },
      );
    });
  };

  const processLoadDataResponse = (
    response: AppResponse,
    query: Query<object>,
    resolve: (value?: QueryResult<object> | PromiseLike<QueryResult<object>> | undefined) => void,
  ): void => {
    if (response.statusCode === 401) {
      props.logout();
    } else {
      setPageSize(query.pageSize);
      setErrorMessage(
        response.status === Status.Error ? 'Niekde nastala chyba! Nepodarilo sa načítať dáta.' : '',
      );
      if (response.status === Status.Ok) {
        setPatientData(response.data);
        setPatient(getPatient(response.data));
      }
      resolve({
        data: response.data,
        page: response.page,
        totalCount: response.total,
      });
    }
  };

  const getPatient = (paPatientData: IPatientData[]): IPatient => {
    const p = { ...patient } as IPatient;
    const pd = paPatientData.length > 0 ? paPatientData[0] : null;
    if (pd !== null) {
      p.id = props.match.params.patientId as number;
      p.name = pd.name;
      p.surname = pd.surname;
      p.age = pd.age;
    }
    return p;
  };

  const actions = [
    {
      icon: 'add',
      isFreeAction: true,
      onClick: () => handleAddBtnAction(),
    },
  ];

  const handleAddBtnAction = () => {
    setDialogOpen(true);
  };

  const closeDialog = (refresh: boolean): void => {
    setDialogOpen(false);
    if (refresh) {
      refreshTableData(refreshCount + 1);
    }
  };

  const components = {
    Actions: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => handleAddBtnAction()}
        >
          Pridaj záznam
        </Button>
      );
    },
  };

  return (
    <Box>
      <Box>
        <IconButton aria-label="Naspäť" onClick={() => props.history.push('/')}>
          <KeyboardBackspaceIcon fontSize="large" />
        </IconButton>
      </Box>
      <Table
        search={false}
        title={getPatientInfo(patient)}
        pageSize={pageSize}
        components={components}
        tableColumns={tableColumns}
        actions={actions}
        refreshCount={refreshCount}
        errorMessage={errorMessage}
        loadData={loadData}
        onRowClick={() => {}}
      />
      <PatientDialog
        loginInfo={props.loginInfo}
        patient={patient}
        dialogOpen={dialogOpen}
        logout={props.logout}
        closeDialog={closeDialog}
      />
    </Box>
  );
};
