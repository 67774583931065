import * as React from 'react';
import './Footer.scss';
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Link,
  Container,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      borderTop: '1px solid #444',
      '& a': {
        textDecoration: 'none',
        color: '#eee',
        '&:hover': {
          color: '#eee',
          textDecoration: 'none',
        },
      },
      '& p': {
        color: '#737373',
        fontSize: '0.8rem',
        marginBottom: '1rem',
      },
    },
  }),
);

export const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" pt={3} pb={2}>
          <Box>
            <img src="/img/metaapp-logo.svg" />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography component="p">
            Copyright ©2020 All rights reserved | Powered by{' '}
            <Link href="https://www.metaapp.sk">MetaApp</Link>
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};
